import { Controller } from "stimulus"
import videojs from 'video.js'
import 'video.js/dist/video-js.css'
import 'videojs-contrib-quality-levels'
import 'videojs-hls-quality-selector'
import 'videojs-thumbnail-sprite'

export default class extends Controller {  
  connect() {}
  initialize() {
    this.thumbnails = JSON.parse(this.element.dataset.thumbnails)
    this.playerTarget = this.element.querySelector("video")

    let videoPlayer = videojs(this.playerTarget, {
      controls: true,
      playbackRates: [0.5, 1, 2],
      autoplay: false,
      fluid: true,
    })

    videoPlayer.thumbnailSprite({
      sprites: this.thumbnails,
    });
    
    videoPlayer.addClass('video-js')
    videoPlayer.addClass('vjs-big-play-centered')
    videoPlayer.hlsQualitySelector({ displayCurrentQuality: true})
  }
  disconnect() {}
}
