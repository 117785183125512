import DatatablesController from '../../../datatables_controller';

export default class extends DatatablesController {
  initialize(){
    super.initialize();
    this.uploaded = document.querySelector('select[name="uploaded"]');
    this.encoded = document.querySelector('select[name="encoded"]');
    this.thumbnail = document.querySelector('select[name="thumbnail"]');
  }

  connect(){
    super.connect();
    this.bindChangeUploaded();
    this.bindChangeEncoded();
    this.bindChangeThumbnail();
  }
  
  bindChangeUploaded(){
    $(this.uploaded).on('select2:select', function(x){
      this.datatable.spinnerCallback(true);
      this.datatable.search(this.uploaded.value, 'uploaded');
      
      this.datatable.on("datatable-on-ajax-done", function(){
        this.datatable.spinnerCallback(false);
      }.bind(this));
    }.bind(this));
  }
  
  bindChangeEncoded(){
    $(this.encoded).on('select2:select', function(x){
      this.datatable.spinnerCallback(true);
      this.datatable.search(this.encoded.value, 'encoded');

      this.datatable.on("datatable-on-ajax-done", function(){
        this.datatable.spinnerCallback(false);
      }.bind(this));
    }.bind(this));
  }
  
  bindChangeThumbnail(){
    $(this.thumbnail).on('select2:select', function(x){
      this.datatable.spinnerCallback(true);
      this.datatable.search(this.thumbnail.value, 'thumbnail');

      this.datatable.on("datatable-on-ajax-done", function(){
        this.datatable.spinnerCallback(false);
      }.bind(this));
    }.bind(this));
  }

  datatableColumns(){
    return [
      {
        field: 'index',
        title: 'No',
        sortable: false,
        width: 25,
        type: 'number',
        selector: false,
        textAlign: 'left',
        template: function(data) {
          return `<span class="font-weight-bolder">${data.index}</span>`;
        }
      },
      {
        field: 'id',
        title: 'id',
        width: 35,
        template: function(data) {
          return `<span class="font-weight-bolder">${data.id}</span>`;
        }
      },
      {
        field: 'host',
        title: 'host',
        sortable: false,
        width: 120,
        template: function(data) {
          return `<span class="font-weight-bolder">${data.host}</span>`;
        }
      },
      {
        field: 'url_video',
        title: 'URL Video',
        sortable: false,
        width: 120,
        template: function(data) {
          return `<a class="font-weight-bolder" href=${data.url_video} >${data.url_name}</a>`;
        }
      },
      {
        field: 'created_at',
        title: 'Tanggal Upload',
        width: 150,
        template: function(data) {
          return `
          <span class="font-weight-bolder">${data.created_at}</span>
          `;
        }
      },
      {
        field: 'status',
        title: 'status',
        sortable: false,
        width: 200,
        template: function(data) {
          const status = data.status;
          const html_status = [];

          status.forEach( (s) => { 
            if (s == 'File Uploaded' || s == 'File Encoded' || s == 'Thumbnail Generated'){
              html_status.push(`<span class='label label-primary label-inline font-weight-lighter mr-2 mt-2'> ${s} </span>`)
            }else{
              html_status.push(`<span class='label label-danger label-inline font-weight-lighter mr-2 mt-2'> ${s} </span>`)
            }
          })

          return html_status.join('')
        }
      },
      {
        field: 'Actions',
        title: 'Actions',
        sortable: false,
        width: 150,
        overflow: 'visible',
        autoHide: false,
        template: function(data) {
          return `
            <a class="btn btn-sm btn-clean btn-icon" href="${data.show_path}" title="Tampilkan">
              <i class="fa fa-eye text-primary"></i>
            </a>
            <a href="${data.delete_path}" data-method="delete" data-confirm="Apakah anda yakin ingin menghapus video ini?" class="btn btn-sm btn-clean btn-icon" title="Delete">
              <i class="la la-trash text-danger"></i>
            </a>
          `;
        },
      }
    ]
  }
}


